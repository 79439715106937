'use client';
import { NewsSubscriptionForm } from '@/features/news-subscription-form';
import { ScrollToTopButton } from '@/features/scroll-to-top-button';
import { usePopupsStore } from '@/shared/store/popupsStore';
import { Button } from '@/shared/ui/Button';
import { Container } from '@/shared/ui/Container';
import { Link } from '@/shared/ui/Link';
import { Portal } from '@/shared/ui/Portal';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import CallbackPopup from '@/widgets/CallbackPopup/ui/CallbackPopup';
import { Nav } from '@/widgets/footer/ui/Nav';
import { cvaNavLink } from '@/widgets/footer/ui/styles';
import SlideAnimation from '@/widgets/SlideAnimation/SlideAnimation';
import { cva } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';
import AimLogoIcon from '~/icons/aim_logo.svg';
import ArrowRightIcon from '~/icons/arrow-right-2.svg';
import TgIcon from '~/icons/tg.svg';
import TiktokIcon from '~/icons/tik-tok.svg';
import VkIcon from '~/icons/vk.svg';
import WhatsAppIcon from '~/icons/whats-app.svg';

interface Props {
  className?: string;
}

export const Footer: FC<Props> = ({
  className,
}) => {
  const currentYear = new Date().getFullYear();
  const showCallbackPopup = usePopupsStore(
    (state) => state.showCallbackPopup
  );
  const setShowCallbackPopup = usePopupsStore(
    (state) => state.setShowCallbackPopup
  );

  const handleClickCallback = () => {
    setShowCallbackPopup(true);
  };

  return (
    <Container
      className={twMergeExt(cvaRoot(), className)}
      variant={'full'}
    >
      <footer>
        {new Array(4)
          .fill(null)
          .map((_, index) => (
            <div
              key={index}
              className={cvaBgBlock({
                index: index as any,
              })}
            />
          ))}

        <Container
          className={cvaContainer()}
          variant={'small'}
        >
          <div className={cvaCols()}>
            <div className={cvaCol()}>
              <div
                className={cvaCallbackContainer()}
              >
                <Button
                  className={cvaButton()}
                  color={'mediumGreen'}
                  size={'medium'}
                  hoverEffect={'highlight'}
                  onClick={() =>
                    handleClickCallback()
                  }
                >
                  Обратная связь
                </Button>
                <div className={cvaPhones()}>
                  <div>
                    <div
                      className={twMergeExt(
                        cvaLabel()
                      )}
                    >
                      <SlideAnimation>
                        Горячая линия
                      </SlideAnimation>
                    </div>
                    <SlideAnimation>
                      <Link
                        className={cvaPhone({
                          size: 'large',
                        })}
                        href={
                          'tel:8 800 222 06 40 '
                        }
                        hoverEffect={'color'}
                      >
                        8 (800) 222-06-40
                      </Link>
                    </SlideAnimation>
                  </div>
                  <div>
                    <div className={cvaLabel()}>
                      <SlideAnimation>
                        Связаться с директором
                      </SlideAnimation>
                    </div>
                    <SlideAnimation>
                      <Link
                        className={twMergeExt(
                          cvaPhone({
                            size: 'small',
                          })
                        )}
                        href={'tel:+79000944970'}
                        hoverEffect={'color'}
                      >
                        +7 (900) 094-49-70
                      </Link>
                    </SlideAnimation>
                  </div>
                </div>
              </div>

              <div className={cvaSocials()}>
                <div
                  className={cvaSocialsLinkList()}
                >
                  <div className={cvaLabel()}>
                    <SlideAnimation>
                      Мессенджеры
                    </SlideAnimation>
                  </div>
                  <SlideAnimation>
                    <Link
                      contentWrapperClassName={cvaNavLink(
                        { highlighted: true }
                      )}
                      hoverEffect={'color'}
                      href={
                        'https://t.me/ALOEsmart'
                      }
                    >
                      <TgIcon
                        className={cvaSocialLinkIcon()}
                      />
                      Telegram{' '}
                      <ArrowRightIcon
                        className={cvaSocialLinkArrowIcon()}
                      />
                    </Link>
                  </SlideAnimation>
                  <SlideAnimation>
                    <Link
                      contentWrapperClassName={cvaNavLink(
                        { highlighted: true }
                      )}
                      hoverEffect={'color'}
                      href={
                        'https://api.whatsapp.com/send?phone=79000944970'
                      }
                    >
                      <WhatsAppIcon
                        className={cvaSocialLinkIcon()}
                      />
                      WhatsApp{' '}
                      <ArrowRightIcon
                        className={cvaSocialLinkArrowIcon()}
                      />
                    </Link>
                  </SlideAnimation>
                  {/* <Link
                    contentWrapperClassName={cvaNavLink(
                      { highlighted: true }
                    )}
                  hoverEffect={'color'}
                    
                    target="_blank"
                    href={
                      'viber://chat?number=%2B79000944970'
                    }
                  >
                    <ViberIcon
                      className={cvaSocialLinkIcon()}
                    />
                    Viber{' '}
                    <ArrowRightIcon
                      className={cvaSocialLinkArrowIcon()}
                    />
                  </Link> */}
                </div>
                <div
                  className={cvaSocialsLinkList()}
                >
                  <div className={cvaLabel()}>
                    <SlideAnimation>
                      Соц. сети
                    </SlideAnimation>
                  </div>
                  <SlideAnimation>
                    <Link
                      contentWrapperClassName={cvaNavLink(
                        { highlighted: true }
                      )}
                      hoverEffect={'color'}
                      href={
                        'https://vk.com/aloesmart'
                      }
                    >
                      <VkIcon
                        className={cvaSocialLinkIcon()}
                      />
                      Вконтакте{' '}
                      <ArrowRightIcon
                        className={cvaSocialLinkArrowIcon()}
                      />
                    </Link>
                  </SlideAnimation>
                  <SlideAnimation>
                    <Link
                      contentWrapperClassName={cvaNavLink(
                        { highlighted: true }
                      )}
                      hoverEffect={'color'}
                      href={
                        'https://www.tiktok.com/@aloesmart'
                      }
                    >
                      <TiktokIcon
                        className={cvaSocialLinkIcon()}
                      />
                      Tik Tok{' '}
                      <ArrowRightIcon
                        className={cvaSocialLinkArrowIcon()}
                      />
                    </Link>
                  </SlideAnimation>
                </div>
              </div>
            </div>

            <div
              className={twMergeExt(
                cvaSeparator(),
                'lg:hidden'
              )}
            />

            <div
              className={twMergeExt(
                cvaCol(),
                'lg:gap-[13.3rem]'
              )}
            >
              <Nav />
              <div
                className={twMergeExt(
                  cvaSeparator(),
                  'lg:hidden'
                )}
              />
              <div className={cvaFormContainer()}>
                <SlideAnimation>
                  <NewsSubscriptionForm
                    className={cvaForm()}
                  />
                </SlideAnimation>
                <SlideAnimation>
                  <ScrollToTopButton
                    className={cvaScrollToTopButton()}
                  />
                </SlideAnimation>
              </div>
            </div>

            <div
              className={twMergeExt(
                cvaSeparator(),
                'lg:hidden'
              )}
            />
          </div>

          <div className={cvaBottomCols()}>
            <div
              className={twMergeExt(
                cvaBottomCol({ variant: 'left' })
              )}
            >
              <div className={cvaLabel()}>
                © 2017-{currentYear} Aloesmart
              </div>
              <div className={cvaLabel()}>
                Все права защищены
              </div>
            </div>
            <div
              className={twMergeExt(
                cvaBottomCol({ variant: 'right' })
              )}
            >
              <Link
                className={cvaLabel()}
                hoverEffect={'color'}
                href={
                  process.env
                    .NEXT_PUBLIC_FRONT_BASE_URL +
                  '/upload/politics.pdf'
                }
                prefetch={false}
              >
                Политика конфиденциальности
              </Link>
              <div
                className={twMergeExt(
                  cvaLabel(),
                  'flex items-center'
                )}
              >
                Разработка сайта —{' '}
                <AimLogoIcon
                  className={
                    'grow-0 shrink-0 w-2.8 h-[1.376rem] ml-0.4 mb-0.4 sm:mb-0.2'
                  }
                />
              </div>
            </div>
          </div>
        </Container>
      </footer>
      <AnimatePresence>
        {showCallbackPopup ? (
          <Portal>
            <CallbackPopup
              onClose={() =>
                setShowCallbackPopup(false)
              }
            />
          </Portal>
        ) : null}
      </AnimatePresence>
    </Container>
  );
};

const cvaRoot = cva([
  'Footer-cvaRoot',
  'relative',
  'mt-6.4 sm:mt-[10.4rem] pt-4 sm:pt-5.6 mb-10 md:mb-0',
]);

const cvaBgBlock = cva(
  [
    'Footer-cvaBgBlock',
    'absolute top-0 left-0',
    'w-full h-[calc(100%_/_4_+_0.8rem_*_3)] sm:h-[calc(100%_/_4_+_1.5rem_*_3)] md:h-[calc(100%_/_3_+_2rem_*_2)] lg:h-[calc(100%_/_3_+_1.1rem_*_2)]',
    'rounded-[4rem] sm:rounded-[6.4rem]',
    'bg-cBannerGreen',
  ],
  {
    variants: {
      index: {
        0: 'top-[calc(0_*_(100%_/_4_-_0.8rem))] sm:top-[calc(0_*_(100%_/_4_-_1.5rem))] md:top-[calc(0_*_(100%_/_3_-_2rem))] lg:top-[calc(0_*_(100%_/_3_-_1.1rem))]',
        1: 'top-[calc(1_*_(100%_/_4_-_0.8rem))] sm:top-[calc(1_*_(100%_/_4_-_1.5rem))] md:top-[calc(1_*_(100%_/_3_-_2rem))] lg:top-[calc(1_*_(100%_/_3_-_1.1rem))]',
        2: 'top-[calc(2_*_(100%_/_4_-_0.8rem))] sm:top-[calc(2_*_(100%_/_4_-_1.5rem))] md:top-[calc(2_*_(100%_/_3_-_2rem))] lg:top-[calc(2_*_(100%_/_3_-_1.1rem))]',
        3: 'block md:hidden top-[calc(3_*_(100%_/_4_-_0.8rem))] sm:top-[calc(3_*_(100%_/_4_-_1.5rem))]',
      },
    },
  }
);

const cvaContainer = cva([
  'Footer-cvaContainer',
  'relative',
]);

const cvaCols = cva([
  'Footer-cvaCols',
  'relative',
  'flex flex-col justify-between gap-2.4 sm:gap-3.2 lg:flex-row lg:gap-0',
]);

const cvaCol = cva([
  'Footer-cvaCol',
  'flex gap-2.4 sm:gap-3.2 flex-col lg:gap-9.6',
]);

const cvaCallbackContainer = cva([
  'Footer-cvaCallbackContainer',
  'flex flex-col-reverse gap-1.2 sm:flex-row-reverse sm:gap-0 items-start justify-between lg:flex-col lg:justify-start lg:gap-4.9',
]);

const cvaButton = cva([
  'Footer-cvaButton',
  'px-3.2',
  'text-bodyMSemibold',
]);

const cvaPhones = cva([
  'Footer-cvaPhones',
  'flex flex-col gap-1.2 sm:gap-2.4',
]);

const cvaPhone = cva(
  [
    'Footer-cvaPhone',
    'inline-flex',
    'whitespace-nowrap',
  ],
  {
    variants: {
      size: {
        large: 'mt-0.8 sm:mt-0.4 text-factoidM',
        small: 'mt-0.8 text-bodyLSemibold',
      },
    },
  }
);

const cvaLabel = cva([
  'Footer-cvaLabel',
  'text-bodyM text-cBlack48',
]);

const cvaSocials = cva([
  'Footer-cvaSocials',
  'flex',
  'gap-0 sm:gap-[11.3rem] md:gap-[23.1rem] lg:gap-[16.5rem]',
]);

const cvaSocialsLinkList = cva([
  'Footer-cvaSocialsLinkList',
  'w-1/2 sm:w-[auto] flex flex-col gap-1.2 sm:gap-1.6',
]);

const cvaSocialLinkIcon = cva([
  'Footer-cvaSocialLinkIcon',
  'w-1.6 h-1.6 sm:w-2.4 sm:h-2.4 shrink-0 grow-0',
]);

const cvaSocialLinkArrowIcon = cva([
  'Footer-cvaSocialLinkArrowIcon',
  'w-1.6 mt-0.2 sm:mt-0 sm:w-2.4 h-1.6 sm:h-2.4 shrink-0 grow-0',
]);

const cvaFormContainer = cva([
  'Footer-cvaFormContainer',
  'flex justify-between items-end lg:justify-stretch lg:gap-[10.6rem]',
  'sm:pr-9.2 lg:pr-9.4',
]);

const cvaForm = cva([
  'Footer-cvaForm',
  'sm:max-w-[43.2rem] lg:max-w-[36.2rem]',
]);

const cvaScrollToTopButton = cva([
  'Footer-cvaScrollToTopButton',
  'self-end mb-1.8',
]);

const cvaSeparator = cva([
  'Footer-cvaSeparator',
  'w-full h-0.1',
  'bg-cBlack16',
]);

const cvaBottomCols = cva([
  'Footer-cvaBottomCols',
  'flex flex-col gap-0.8 sm:flex-row sm:justify-between sm:gap-0',
  'pt-2.4 pb-4 sm:py-4.8 lg:mt-4.8 lg:border-t lg:border-cBlack16',
]);

const cvaBottomCol = cva(
  [
    'Footer-cvaBottomCol',
    'flex flex-col gap-x-3.2 gap-y-0.8 items-center md:flex-row md:justify-between',
    'flex-1',
  ],
  {
    variants: {
      variant: {
        left: 'md:max-w-[39rem] lg:max-w-[47.4rem] xl:max-w-[48.4rem]',
        right:
          'lg:pr-0.9 md:max-w-[48.5rem] lg:max-w-[57rem] xl:max-w-[65.8rem]',
      },
    },
  }
);
