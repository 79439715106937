'use client';
import {
  FC,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { motion } from 'framer-motion';
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock-upgrade';
import CloseIcon from '~/icons/close.svg';
import { useOnClickOutside } from '@/shared/hooks/useOnClickOutside';
import { cva } from 'class-variance-authority';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { useLenis } from 'lenis/react';
import { SmoothScroll } from '@/features/smooth-scroll';

interface Props {
  scrollClassName?: string;
  popupClassName?: string;
  overlayClassName?: string;
  closeButtonClassName?: string;
  hideCloseButton?: boolean;
  onClose: () => void;
  closeOnClickOutside?: boolean;
  children: ReactNode;
}

export const Popup: FC<Props> = ({
  scrollClassName,
  popupClassName,
  overlayClassName,
  closeButtonClassName,
  hideCloseButton,
  onClose,
  closeOnClickOutside = true,
  children,
}) => {
  const scrollRef = useRef<any>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const rootLenis = useLenis()!;

  useEffect(() => {
    let refValue = scrollRef.current;

    if (
      scrollRef.current.wrapper &&
      popupRef.current &&
      rootLenis
    ) {
      disableBodyScroll(
        scrollRef.current.wrapper,
        { reserveScrollBarGap: true }
      );
      refValue = scrollRef.current.wrapper;
      rootLenis.stop();
    }

    return () => {
      if (refValue && rootLenis) {
        enableBodyScroll(refValue);
        rootLenis.start();
      }
    };
  }, []);

  useOnClickOutside(
    popupRef,
    closeOnClickOutside ? onClose : () => {}
  );

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,
          ease: 'easeOut',
        }}
        className={twMergeExt(
          cvaOverlay(),
          overlayClassName
        )}
        onClick={onClose}
      />

      <SmoothScroll
        ref={scrollRef}
        className={twMergeExt(
          cvaScroll(),
          scrollClassName
        )}
      >
        <motion.div
          ref={popupRef}
          className={twMergeExt(
            cvaPopup(),
            popupClassName
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            ease: 'easeOut',
          }}
        >
          {!hideCloseButton && (
            <button
              className={twMergeExt(
                'absolute top-1.6 right-1.6 w-4.8 h-4.8',
                closeButtonClassName
              )}
              type={'button'}
              onClick={onClose}
            >
              <CloseIcon />
            </button>
          )}

          {children}
        </motion.div>
      </SmoothScroll>
    </>
  );
};

const cvaOverlay = cva([
  'Popup-cvaOverlay',
  'fixed left-0 top-0 z-[98]',
  'w-full h-full',
  'bg-cBlack32',
]);

const cvaScroll = cva([
  'Popup-cvaScroll',
  'fixed left-0 top-0 z-[99]',
  'w-full h-full overflow-auto',
  'p-4',
  'text-center',
  'translate-x-0',
  'before:inline-block before:w-0 before:h-full before:align-middle',
  '[&>div]:contents',
]);

const cvaPopup = cva([
  'Popup-cvaPopup',
  'relative',
  'inline-block',
  'text-left align-middle',
  'bg-cWhite',
]);
